import { makeContextModule } from '@cian/react-utils';
import React, { FC, useMemo } from 'react';

import { useAdfoxParams } from './internal/hooks/useAdfoxParams';

import type { AdfoxContextData } from './types';
import { PParams } from '../../../types';

const contextData = makeContextModule<AdfoxContextData>({ contextName: 'AdfoxContextData' });

const {
  Context: { Provider },
} = contextData;

export const { useContext: useAdfoxContext, withContext: withAdfoxContext, Context: AdfoxContext } = contextData;

export type AdfoxContextProviderProps = Omit<AdfoxContextData, 'params' | 'isHidden'> & {
  children: () => JSX.Element;
  pParams: PParams;
};

export const AdfoxContextProvider: FC<AdfoxContextProviderProps> = props => {
  const { children, pParams, size, place, postViewAnalyticsData } = props;

  const params = useAdfoxParams(pParams);

  const contextValue = useMemo((): AdfoxContextData | null => {
    if (!params) {
      return null;
    }

    return {
      postViewAnalyticsData,
      params,
      size,
      place,
    };
  }, [params, place, postViewAnalyticsData, size]);

  if (!contextValue) {
    return null;
  }

  return <Provider value={contextValue}>{children()}</Provider>;
};
