import { TelegramLinkData } from '../../constants/telegramLinkData';
import {
  ETelegramChannel,
  IJournalAttributesSchema,
} from '../../repositories/journal/entities/journal/JournalAttributesSchema';

interface IGetLinkData {
  tags?: IJournalAttributesSchema['tags'];
  telegramChannel?: ETelegramChannel;
  isB2BModeEnabled?: boolean;
}

/**
 * Возвращает данные для ссылки ТГ-канала
 */
export const getLinkData = ({ tags, telegramChannel, isB2BModeEnabled }: IGetLinkData = {}) => {
  /** ТГ-канал для поста непосредственно закреплен в админке (выпиливаем коммерческий @cian_cre) */
  if (telegramChannel && telegramChannel !== ETelegramChannel.Commercial) {
    return TelegramLinkData[telegramChannel];
  }

  /** Если нет тегов, то канал официальный */
  if (!tags?.length) {
    return TelegramLinkData[ETelegramChannel.Private];
  }

  /** ТГ-канал по тегам и b2b для Посуточной */
  if (isB2BModeEnabled && (tags.includes('посуточно') || tags.includes('посуточная аренда'))) {
    return TelegramLinkData[ETelegramChannel.Daily_rent];
  }

  /** ТГ-канал по тегам для профи */
  if (tags.includes('профессионалам') || tags.includes('риелторы')) {
    return TelegramLinkData[ETelegramChannel.Professionals];
  }

  /** По умолчанию канал официальный */
  return TelegramLinkData[ETelegramChannel.Private];
};
