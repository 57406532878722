/**
 * Приводит дату к формату YYYY-MM-DDThh:mm:ss±hh:mm
 */
export const toISOStringWithTimezone = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const dif = tzOffset >= 0 ? '+' : '-';
  const pad = (number: number) => (number < 10 ? '0' : '') + number;

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzOffset) / 60)) +
    ':' +
    pad(Math.abs(tzOffset) % 60)
  );
};
