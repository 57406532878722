import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tabs, TabsSkeleton } from './components/Tabs';
import { Counts, CountsSkeleton } from './components/Counts';
import { TabsContent, TabsContentSkeleton } from './components/TabsContent';
import { PostTabsLayout } from '../../components/PostTabsLayout';
import { buildPostUrl } from '../../utils';
import { useLikes } from './utils';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { POST_COMMENTS_ID } from '../../constants';
import { PERMISSIONS } from '../../../app/permissions/permissionsNew';
import { ERequestStatus } from '../../types/requestStatus';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

export enum ETabs {
  Authors = 'authors',
  Tags = 'tags',
}

/**
 * Контейнер табов с авторами и тегами для поста
 */
export const PostTabsContainer = () => {
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);
  const [activeTab, setActiveTab] = useState(ETabs.Authors);
  const { handleLikeClick, isLikesLoading } = useLikes();

  useEffect(() => {
    if (!('id' in post)) {
      return;
    }

    const {
      attributes: { authorsLink, tags, authorObject, type },
    } = post;
    const isShownAuthorObject = (authorObject && [EType.Blogs, EType.Questions].includes(type)) || false;

    if (authorsLink?.length) {
      setActiveTab(ETabs.Authors);

      return;
    }

    if (isShownAuthorObject) {
      setActiveTab(ETabs.Authors);

      return;
    }

    if (tags?.length) {
      setActiveTab(ETabs.Tags);

      return;
    }
  }, [post]);

  if (ERequestStatus.Loading === post.status) {
    return (
      <PostTabsLayout>
        <TabsSkeleton>
          <CountsSkeleton />
        </TabsSkeleton>

        <TabsContentSkeleton />
      </PostTabsLayout>
    );
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    id,
    attributes: {
      likesCount,
      dislikesCount,
      commentsCount,
      authorsLink,
      authorObject,
      tags,
      type,
      slug,
      userLikeValue,
    },
  } = post;
  const permissions = PERMISSIONS[type];
  const isShownAuthorObject = (authorObject && [EType.Blogs, EType.Questions].includes(type)) || false;

  return (
    <PostTabsLayout>
      <Tabs
        activeTab={activeTab}
        hasAuthors={Boolean(authorsLink?.length) || isShownAuthorObject}
        hasTags={Boolean(tags?.length)}
        onChange={setActiveTab}
      >
        <Counts
          likesCount={likesCount ?? undefined}
          dislikesCount={dislikesCount ?? undefined}
          commentsCount={commentsCount ?? undefined}
          isLikesAndDislikesShown={EType.News !== type}
          isCommentsShown={permissions.canSeeComments(user, post)}
          isLikesDisabled={isLikesLoading || !permissions.canLikes(user, post)}
          postCommentsUrl={buildPostUrl({ type, slug, id, anchor: POST_COMMENTS_ID })}
          userLikeValue={userLikeValue}
          onLikeClick={
            permissions.canLikes(user, post) && (EType.Blogs === type || EType.Questions === type)
              ? likeType => {
                  void handleLikeClick({ postId: id, postType: type, likeType });
                }
              : undefined
          }
        />
      </Tabs>

      <TabsContent
        activeTab={activeTab}
        authorLinksData={authorsLink ?? undefined}
        authorObject={(isShownAuthorObject && authorObject) || undefined}
        tags={tags ?? undefined}
      />
    </PostTabsLayout>
  );
};
