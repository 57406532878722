import { ETelegramChannel } from '../repositories/journal/entities/journal/JournalAttributesSchema';
import { ITelegramLinkData } from '../types/telegramLinkData';

/**
 * Данные для ссылок ТГ-каналов (по типам аудитории)
 */
export const TelegramLinkData: { [key in ETelegramChannel]: ITelegramLinkData } = {
  [ETelegramChannel.Private]: {
    label: '@cian_official',
    link: 'https://t.me/cian_official',
    title: 'Подписывайтесь на наш канал в Telegram',
  },
  [ETelegramChannel.Commercial]: {
    label: '@cian_cre',
    link: 'https://t.me/cian_cre',
    title: 'Подписывайтесь на Циан CRE в Telegram',
  },
  [ETelegramChannel.Professionals]: {
    label: '@cian_realtor',
    link: 'https://t.me/cian_realtor',
    title: 'Подписывайтесь на Циан для профи в Telegram',
  },
  [ETelegramChannel.Daily_rent]: {
    label: 'Циан.Посуточная',
    link: 'https://t.me/+tq_w8QAmqpU0ZmZi',
    title: 'Подписывайтесь на Циан для посуточной недвижимости в Telegram',
  },
};
