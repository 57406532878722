import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { hydrate } from 'react-dom';
import { TDeviceType } from '@cian/ui-kit';

import { IParseBlockquotesItem } from './parseBlockquotes';
import { PostBlockquote } from '../../components/PostBlockquote';
import { TPostContentBlockquoteTheme } from '../../types/postContentBlockquotes';
import { UserCard } from '../../components/UserCard';
import { PostBlockquoteUserCardLayout } from '../../components/PostBlockquoteUserCardLayout';

interface IUseContentBeforeAfterGalleries {
  html: string;
  blockquotes: IParseBlockquotesItem[];
  deviceType: TDeviceType;
}

/**
 * Рендерит и управляет Цитатами
 */
export const useContentBlockquotes = ({ html, blockquotes, deviceType }: IUseContentBeforeAfterGalleries): string => {
  /** Рендерит для CSR */
  useEffect(() => {
    blockquotes.forEach(({ image, description, theme, userCard, id }: IParseBlockquotesItem) => {
      const container = document.getElementById(id);

      if (container) {
        hydrate(
          <PostBlockquote image={image} text={description} theme={theme as TPostContentBlockquoteTheme}>
            {userCard?.title && (
              <PostBlockquoteUserCardLayout isWhiteColor={theme === 'blue' && true}>
                <UserCard
                  imageSrc={userCard.image}
                  hasAvatar={Boolean(userCard.image)}
                  name={userCard.title}
                  description={userCard.description}
                  size={deviceType === 'phone' ? 'M' : 'XL'}
                />
              </PostBlockquoteUserCardLayout>
            )}
          </PostBlockquote>,
          container,
        );
      }
    });
  }, [blockquotes, deviceType]);

  /** Рендерит для SSR */
  if (typeof window === 'undefined') {
    return blockquotes.reduce((acc, { image, description, theme, userCard, id }) => {
      return acc.replace(
        new RegExp(`<div id="${id}"[^>]*?></div>`),
        `<div id="${id}">${renderToString(
          <PostBlockquote image={image} text={description} theme={theme as TPostContentBlockquoteTheme}>
            {userCard?.title && (
              <PostBlockquoteUserCardLayout isWhiteColor={theme === 'blue' && true}>
                <UserCard
                  imageSrc={userCard.image}
                  hasAvatar={Boolean(userCard.image)}
                  name={userCard.title}
                  description={userCard.description}
                  size={deviceType === 'phone' ? 'M' : 'XL'}
                />
              </PostBlockquoteUserCardLayout>
            )}
          </PostBlockquote>,
        )}</div>`,
      );
    }, html);
  }

  return html;
};
