import React from 'react';

import { Typography } from './components/Typography';
import { Title } from './components/Title';
import { Details } from './components/Details';
import { Description } from './components/Description';
import { MainImage } from './components/MainImage';
import { Microdata } from './components/Microdata';
import { ModerationBar } from './components/ModerationBar';
import { DeclineReason } from '../DeclineReason';
import { UserCardForAuthorObject } from '../UserCard';
import { EType, IJournalAttributesSchema } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import * as s from './Post.css';

export interface IPost
  extends Pick<
    IJournalAttributesSchema,
    'commentsCount' | 'image' | 'numViews' | 'subtitle' | 'title' | 'type' | 'blockReason' | 'authorObject'
  > {
  content: string;
  dateModified: IJournalAttributesSchema['updatedAt'];
  datePublished: IJournalAttributesSchema['datePublish'];
  postId: number;
  postUrl: string;
  postCommentsUrl: string;
  authorPostsCount?: number;
  authorId?: number;
  postRegionName?: string;
  canViewComments?: boolean;
  canPublishPost?: boolean;
  canEditPost?: boolean;
  onEditButtonClick?(): void;
  onPublishButtonClick?(): void;
}

export const Post = ({
  authorPostsCount,
  authorId,
  authorObject,
  commentsCount,
  content,
  dateModified,
  datePublished,
  image,
  numViews,
  postRegionName,
  postId,
  postUrl,
  postCommentsUrl,
  subtitle,
  title,
  type,
  blockReason,
  canViewComments,
  canPublishPost,
  canEditPost,
  onEditButtonClick,
  onPublishButtonClick,
}: IPost) => {
  return (
    <div
      className={s['wrapper']}
      itemScope
      itemType={`https://schema.org/${type === EType.News ? 'NewsArticle' : 'Article'}`}
    >
      {blockReason && (
        <div className={s['block-reason-wrapper']}>
          <DeclineReason code={blockReason} />
        </div>
      )}

      <div className={s['title-wrapper']}>
        <Title>{title}</Title>
      </div>

      {authorObject && (
        <div className={s['author-wrapper']}>
          <UserCardForAuthorObject {...authorObject} />
        </div>
      )}

      <div className={s['details-wrapper']}>
        <Details
          authorPostsCount={authorPostsCount}
          authorId={authorId}
          commentsCount={commentsCount}
          datePublished={datePublished}
          canViewComments={canViewComments}
          numViews={numViews}
          postRegionName={postRegionName}
          postCommentsUrl={postCommentsUrl}
          type={type}
        />
      </div>

      {(canPublishPost || canEditPost) && (
        <div className={s['moderation-wrapper']}>
          <ModerationBar
            canPublishPost={canPublishPost}
            canEditPost={canEditPost}
            onEditButtonClick={onEditButtonClick}
            onPublishButtonClick={onPublishButtonClick}
          />
        </div>
      )}

      <Microdata
        authorName={authorObject?.fullName ?? undefined}
        dateModified={dateModified}
        datePublished={datePublished}
        postId={postId}
        postUrl={postUrl}
      />

      {image && (
        <div className={s['main-image-wrapper']}>
          <MainImage src={image} alt={title} />
        </div>
      )}

      {subtitle && (
        <div className={s['description-wrapper']}>
          <Description>{subtitle}</Description>
        </div>
      )}

      <Typography>
        <div
          className={`${s['content-wrapper']} fr-view`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          itemProp="articleBody"
        />
      </Typography>
    </div>
  );
};
