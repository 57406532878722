import React, { FC } from 'react';
import { AdfoxContextData, AdfoxContextProvider } from '../../../context/AdfoxContext';
import { PParams } from '../../../../types';
import { AdfoxBannerContainer } from '../../../../AdfoxBannerContainer';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'footer',
  place__DEPRECATED: 'journal',
  name__DEPRECATED: 'Journal_article_footer',
};

const P_PARAMS: PParams = { p1: 'ddcgf', p2: 'y' };

const SIZE: TAdFoxBannerSizeProps = { minHeight: '500px', width: '100%' };

export const AdfoxBannerArticleFooterDesktop: FC = () => {
  return (
    <AdfoxContextProvider
      postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}
      place="articleFooter"
      size={SIZE}
      pParams={P_PARAMS}
    >
      {() => <AdfoxBannerContainer />}
    </AdfoxContextProvider>
  );
};
