import React, { FC } from 'react';
import { AdfoxContextData, AdfoxContextProvider } from '../../context/AdfoxContext';
import { PParams } from '../../../types';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'aside',
  place__DEPRECATED: 'journal',
  name__DEPRECATED: 'Journal_article_aside',
};

const P_PARAMS: PParams = { p1: 'ddcgz', p2: 'fkhj' };

const SIZE: TAdFoxBannerSizeProps = { minHeight: '398px', width: '100%' };

export const AdfoxBannerArticleAsideDesktop: FC = () => {
  return (
    <AdfoxContextProvider
      postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}
      place="articleAside"
      size={SIZE}
      pParams={P_PARAMS}
    >
      {() => <AdfoxBannerContainer />}
    </AdfoxContextProvider>
  );
};
