import sanitizeHtml from 'sanitize-html';

/**
 * Производит санитизацию комментария к дискуссии
 */
export const sanitizeDiscussionComment = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: [],
    allowedAttributes: {},
  });
