import React, { useContext, useEffect, useState } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';

import { RecommendedOffers } from '../../components/RecommendedOffers';
import { ScrollingWithControls, Scrolling } from '../../components/Scrolling';
import {
  selectOffersFromRecommendedApi,
  selectOffersFromRecommendedApiStatus,
  selectOffersFromHistory,
  selectOffersFromHistoryStatus,
} from '../../selectors/recommendedOffers';
import { getOffersFromHistory, getOffersFromRecommendedApi } from '../../actions/recommendedOffers';
import { ApplicationContext } from '../../utils';
import { trackRecommendedOfferClick } from '../../utils/trackers/recommendedOffers';
import { selectBreadCrumbs } from '../../selectors/breadCrumbs';
import { selectUser } from '../../selectors/user';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { IRecommendedOfferSchema } from '../../repositories/search-engine/entities/recommended_offers/RecommendedOfferSchema';
import * as s from './RecommendedOffersContainer.css';

enum ETabs {
  History = 'history',
  Recommendations = 'recommendations',
}

const TAB_CONTENT_HEIGHT = '314px';
const SCROLL_LENGTH = 256;

/**
 * Выводит список подобных статей
 */
export const RecommendedOffersContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const historyOffers = useSelector(selectOffersFromHistory);
  const historyOffersStatus = useSelector(selectOffersFromHistoryStatus);
  const recommendedApiOffers = useSelector(selectOffersFromRecommendedApi);
  const recommendedApiOffersStatus = useSelector(selectOffersFromRecommendedApiStatus);
  const deviceType = useDeviceType();
  const [activeTab, setActiveTab] = useState(recommendedApiOffers.length ? ETabs.Recommendations : ETabs.History);
  const isDesktop = () => deviceType === 'desktop';
  const {
    custom: { isRecommendedOffersBlockEnabled },
  } = useContext(ApplicationContext);
  const { userGaData } = useSelector(selectUser);
  const breadCrumbs = useSelector(selectBreadCrumbs);

  /** Загружает список просмотренных ранее объявлений */
  useEffect(() => {
    /** Если объявления предварительно загружены -> ничего не делаем */
    if (historyOffersStatus !== ERequestStatus.Initial || !isRecommendedOffersBlockEnabled) {
      return;
    }

    dispatch(getOffersFromHistory()).finally();
  }, [historyOffersStatus, dispatch, isRecommendedOffersBlockEnabled]);

  /** Загружает список рекомендованных объявлений */
  useEffect(() => {
    /** Если объявления предварительно загружены -> ничего не делаем */
    if (recommendedApiOffersStatus !== ERequestStatus.Initial || !isRecommendedOffersBlockEnabled) {
      return;
    }

    dispatch(getOffersFromRecommendedApi()).finally();
  }, [recommendedApiOffersStatus, dispatch, isRecommendedOffersBlockEnabled]);

  /** Устанавливает активный таб после загрузки оферов */
  useEffect(() => {
    if (historyOffersStatus !== ERequestStatus.Succeed && recommendedApiOffersStatus !== ERequestStatus.Succeed) {
      return;
    }

    setActiveTab(recommendedApiOffers.length ? ETabs.Recommendations : ETabs.History);
  }, [historyOffersStatus, recommendedApiOffers.length, recommendedApiOffersStatus]);

  /**
   * При клике по объявлению открываем
   * страницу объявления в отдельной вкладке
   */
  const handleOfferClick = (offer: IRecommendedOfferSchema) => {
    const { realtyId, url, isFromBuilder } = offer;

    trackRecommendedOfferClick({ userGa: userGaData, breadCrumbs, deviceType, realtyId, url, isFromBuilder });
    window.open(`${url}?cian_source=magazine&cian_medium=recommended_objects&cian_campaign=bottom_block`, '_blank');
  };

  const tabs = [
    {
      type: ETabs.Recommendations,
      title: 'Могут подойти',
      content: (
        <RecommendedOffers offers={recommendedApiOffers} height={TAB_CONTENT_HEIGHT} onOfferClick={handleOfferClick} />
      ),
      hasOffers: Boolean(recommendedApiOffers.length),
    },
    {
      type: ETabs.History,
      title: 'Недавно вы смотрели',
      content: <RecommendedOffers offers={historyOffers} height={TAB_CONTENT_HEIGHT} onOfferClick={handleOfferClick} />,
      hasOffers: Boolean(historyOffers.length),
    },
  ];

  /**
   * Переключает таб с рекомендованными оферами
   */
  const toggleTab = (activeTab: ETabs) => {
    setActiveTab(activeTab);
  };

  if ((!historyOffers.length && !recommendedApiOffers.length) || !isRecommendedOffersBlockEnabled) {
    return null;
  }

  return (
    <div className={s['wrapper']}>
      <div className={s['tabs']}>
        {tabs
          .filter(({ hasOffers }) => hasOffers)
          .map(({ type, title }, index) => (
            <div
              key={`tab-${index}`}
              className={`${s['title']} ${activeTab === type ? s['_active'] : ''}`}
              onClick={() => toggleTab(type)}
            >
              {title}
            </div>
          ))}
      </div>

      <div className={s['tabs-content']} style={{ height: TAB_CONTENT_HEIGHT }}>
        {tabs
          .filter(({ hasOffers }) => hasOffers)
          .map(({ type, content }, index) => (
            <div
              key={`tab-content-${index}`}
              className={`${s['tab-content']} ${activeTab === type ? s['_active'] : ''}`}
            >
              {isDesktop() ? (
                <ScrollingWithControls scrollLength={SCROLL_LENGTH} controlsType={'absolute'}>
                  {content}
                </ScrollingWithControls>
              ) : (
                <Scrolling>{content}</Scrolling>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
