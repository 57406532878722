import { IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { useBooleanState } from '@cian/react-utils';

import React, { memo, useCallback, useMemo } from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';

type AdfoxBannerContainerProps = {
  wrapperClassName?: string;
  galleryPosition?: number;
  position?: number;
  onError?(error: IAdFoxBannerOnErrorError): void;
  onStub?(): void;
};

type HandleError = (error: IAdFoxBannerOnErrorError) => void;

export const AdfoxBannerContainer = memo<AdfoxBannerContainerProps>(function AdfoxBannerContainer(props) {
  const { wrapperClassName, position, galleryPosition, onStub, onError } = props;

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();
  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const handleStub = useCallback<VoidFunction>(() => {
    onStub?.();

    setBannerHidden();
  }, [onStub, setBannerHidden]);

  const handleError = useCallback<HandleError>(
    error => {
      onError?.(error);

      setBannerHidden();
    },
    [onError, setBannerHidden],
  );

  return useMemo<JSX.Element | null>(() => {
    if (isBannerHidden) {
      return null;
    }

    const Banner = withAdfoxPostViewAnalytics(
      AdfoxBanner,
      {
        ...postViewAnalyticsData,
        galleryPosition,
        position,
      },
      { wrapperClassName },
    );

    return <Banner {...size} params={params} onStub={handleStub} onError={handleError} />;
  }, [isBannerHidden]);
});
