import React from 'react';

import { toISOStringWithTimezone } from '../../../../utils';
import { IPost } from '../../Post';

interface IMicrodata extends Pick<IPost, 'dateModified' | 'datePublished' | 'postUrl' | 'postId'> {
  authorName?: string;
}

/**
 * Микроразметка
 */
export const Microdata = ({ dateModified, datePublished, authorName, postId, postUrl }: IMicrodata) => {
  return (
    <>
      <meta itemProp="identifier" content={String(postId)} />
      <link itemProp="mainEntityOfPage" href={`https://cian.ru${postUrl}`} />

      <div itemProp="author" itemScope itemType="https://schema.org/Organization">
        <meta itemProp="name" content={authorName || 'Циан Журнал'} />
        <meta itemProp="url" content="https://www.cian.ru/magazine/" />
      </div>

      <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
        <meta itemProp="name" content="Циан Журнал" />
        <meta itemProp="logo" content={'https://content.cdn-cian.ru/realty/logo-cian.svg'} />
      </div>

      <meta itemProp="dateModified" content={toISOStringWithTimezone(new Date(dateModified))} />
      <meta itemProp="datePublished" content={toISOStringWithTimezone(new Date(datePublished))} />
    </>
  );
};
