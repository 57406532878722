import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeviceType } from '@cian/ui-kit';

import { PostBlockquote } from '../../components/PostBlockquote';
import { UserCard } from '../../components/UserCard';
import { PostBlockquoteUserCardLayout } from '../../components/PostBlockquoteUserCardLayout';
import { parseBlockquotes } from './parseBlockquotes';
import { selectPostContentBlockquotes } from '../../selectors/postContentBlockquotes';
import { postContentBlockquoteSet, postContentBlockquoteReset } from '../../actions/postContentBlockquote';
import { TPostContentBlockquoteTheme, TPostContentBlockquoteState } from '../../types/postContentBlockquotes';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит и управляет Цитатами
 */
export const useContentBlockquotesNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const blockquotes = useSelector(selectPostContentBlockquotes);
  const deviceType = useDeviceType();

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      dispatch(postContentBlockquoteReset());
      setPrevPathname(pathname);
      setIsParsed(false);
    }
  }, [dispatch, pathname, prevPathname]);

  /** Рендерит Цитаты */
  useEffect(() => {
    if (!blockquotes.length || !canRender) {
      return;
    }

    blockquotes.forEach(({ id, image, description, theme, userCard }: TPostContentBlockquoteState) => {
      const root = document.getElementById(id);

      if (root) {
        hydrate(
          <PostBlockquote image={image} text={description} theme={theme as TPostContentBlockquoteTheme}>
            {userCard?.title && (
              <PostBlockquoteUserCardLayout isWhiteColor={theme === 'blue' && true}>
                <UserCard
                  imageSrc={userCard.image}
                  hasAvatar={Boolean(userCard.image)}
                  name={userCard.title}
                  description={userCard.description}
                  size={deviceType === 'phone' ? 'M' : 'XL'}
                />
              </PostBlockquoteUserCardLayout>
            )}
          </PostBlockquote>,
          root,
        );
      }
    });
  }, [blockquotes, canRender, deviceType]);

  /** Парсит параметры Цитат */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    if (blockquotes.length) {
      setIsParsed(true);
      done();

      return;
    }

    const { items, html } = parseBlockquotes(postContent, ({ id }) => `<div id="${id}"></div>`);

    if (items.length) {
      setPostContent(html);

      items.map(({ id, image, description, userCard, theme, position }) =>
        dispatch(
          postContentBlockquoteSet({
            id,
            image,
            description,
            userCard,
            theme: theme ? (theme as TPostContentBlockquoteTheme) : undefined,
            position,
          }),
        ),
      );
    }

    setIsParsed(true);
    done();
  }, [blockquotes.length, canParse, dispatch, done, isParsed, postContent, setPostContent]);

  /** При размонтировании сбрасывает все Цитаты */
  useEffect(() => {
    return () => {
      dispatch(postContentBlockquoteReset());
    };
  }, [dispatch]);
};
