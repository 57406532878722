import { IPostItemAttributes } from '../../../types/posts';
import { ETelegramChannel } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { isProfessionalAudience } from './isProfessionalAudience';
import { isCommercialAudience } from './isCommercialAudience';

/**
 * Данные для кнопок ТГ-каналов (по типам аудитории)
 */
export const TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE: Record<ETelegramChannel, Record<'link' | 'title', string>> = {
  [ETelegramChannel.Private]: {
    link: 'https://t.me/cian_official',
    title: 'Подписывайтесь на наш канал в Telegram',
  },
  [ETelegramChannel.Commercial]: {
    link: 'https://t.me/cian_cre',
    title: 'Подписывайтесь на Циан CRE в Telegram',
  },
  [ETelegramChannel.Professionals]: {
    link: 'https://t.me/cian_realtor',
    title: 'Подписывайтесь на Циан для профи в Telegram',
  },
  [ETelegramChannel.Daily_rent]: {
    link: 'https://t.me/+tq_w8QAmqpU0ZmZi',
    title: 'Подписывайтесь на Циан для посуточной недвижимости в Telegram',
  },
};

/**
 * Возвращает пару (ссылка и заголовок) для кнопки ТГ-канала
 */
export const getTelegramLinkData = (
  tags: Pick<IPostItemAttributes, 'tags'>['tags'],
  tgAudience?: ETelegramChannel | null,
) => {
  // 1: Тип аудитории пришел с бэка
  if (tgAudience) {
    return TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE[tgAudience];
  }

  if (!tags || !tags.length) {
    return TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE[ETelegramChannel.Private];
  }

  // 2: Сначала проверяем коммерческую
  if (isCommercialAudience(tags)) {
    return TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE[ETelegramChannel.Commercial];
  }

  // 3: Потом - профиков
  if (isProfessionalAudience(tags)) {
    return TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE[ETelegramChannel.Professionals];
  }

  return TELEGRAM_LINK_DATA_BY_AUDIENCE_TYPE[ETelegramChannel.Private];
};
