import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { UIText2 } from '@cian/ui-kit/typography';

import { UserCardForAuthorObject } from '../../../../components/UserCard';
import { ETabs } from '../../PostTabsContainer';
import { IAuthorsLinkSchema } from '../../../../repositories/journal/entities/journal/AuthorsLinkSchema';
import { IUserDataItemSchema } from '../../../../repositories/journal/entities/user_data_item/UserDataItemSchema';
import * as s from './TabsContent.css';

interface ITabsContent {
  activeTab: ETabs;
  authorLinksData?: IAuthorsLinkSchema[];
  authorObject?: IUserDataItemSchema;
  tags?: string[];
}

/**
 * Табы авторов и тегов
 */
export const TabsContent = ({ activeTab, authorLinksData, authorObject, tags }: ITabsContent) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const activeTabElement = ref.current.querySelector('[data-is-actived="true"]');

    if (!activeTabElement) {
      return;
    }

    ref.current.style.height = `${activeTabElement.getBoundingClientRect().height}px`;
  }, [activeTab]);

  return (
    <div className={s['wrapper']} ref={ref}>
      {authorLinksData?.length ? (
        <div
          className={`${s['content']} ${activeTab === ETabs.Authors ? s['_active'] : ''}`}
          data-is-actived={activeTab === ETabs.Authors}
        >
          {authorLinksData.map(({ name, path }, index) => {
            return path ? (
              <Link key={index} to={path} className={s['link']}>
                <UIText2 color={'primary_100'}>{name}</UIText2>
              </Link>
            ) : (
              <UIText2 key={index} color={'black_100'}>
                {name}
              </UIText2>
            );
          })}
        </div>
      ) : null}

      {authorObject ? (
        <div
          className={`${s['content']} ${activeTab === ETabs.Authors ? s['_active'] : ''}`}
          data-is-actived={activeTab === ETabs.Authors}
        >
          <div className={s['user-card-wrapper']}>
            <UserCardForAuthorObject {...authorObject} />
          </div>
        </div>
      ) : null}

      {tags?.length ? (
        <div
          className={`${s['content']} ${activeTab === ETabs.Tags ? s['_active'] : ''}`}
          data-is-actived={activeTab === ETabs.Tags}
        >
          {tags.map((tag, index) => (
            <Link key={index} to={`/magazine/?tag=${tag}`} className={s['link']}>
              <UIText2 color={'primary_100'}>{tag}</UIText2>
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
};
